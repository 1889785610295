.cards-frame {
    display                   : flex;
    flex-direction            : column;
    align-items               : center;
    justify-content           : center;
    font-size                 : 50px;
    padding                   : 20px 0;
}

.cards-title {
    font-family               : 'Staatliches';
    padding                   : 35px 0;
}
