.banner {
    display                   : grid;
    align-items               : center;
    justify-content           : center;
    color                     : #fff;
    height                    : 100vh;
    width                     : 100%;
    overflow                  : hidden;
}

.banner-elem {
    grid-row                  : 1;
    grid-column               : 1;
}

.banner-content {
    display                   : flex;
    flex-direction            : column;
    align-items               : center;
    z-index                   : 2;
}

.banner-title {
    display                   : flex;
    align-items               : center;
    justify-content           : center;
    text-align                : center;
    z-index                   : 2;
    font-family               : 'Staatliches';
    font-size                 : 15vw;
    font-weight               : 600;
    /* text-shadow               : 0px 0px 100px var(--color-banner-title-text-shadow); */
}

.banner-overlay {
    z-index                   : 1;
    height                    : 100%;
    width                     : 100%;
}

@media only screen and (max-width: 960px) {
    .banner-title {
        font-size             : 20vw;
    }
}
