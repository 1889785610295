:root {
  --rose: #f78a74;
}

.navbar {
  /* background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 100%); */
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
  transition: all 0.3s ease-in;
  margin: 0 0 -80px 0;
}

.navbar.nav-mob-active {
  background-color: white;
}

.navbar.scrolled {
  background-color: white;
  box-shadow: 0px 11px 19px 0px #00000010;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}

.navbar-logo {
  color: white;
  position: absolute;
  height: 100%;
  left: 10px;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  transition: color 0.3s ease-out;
}

.navbar-logo.scrolled {
  color: #555;
}

.navbar-logo:hover {
  color: var(--rose);
}

.fa-paw {
  margin-left: 0.5rem;
  font-size: 1.8rem;
  color: #f7b1a2;
}

.nav-menu {
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-style: none;
  text-align: center;
  width: 60vw;
  margin-right: 2rem;
  overflow: auto;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: white;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  transition: color 0.3s ease-out;
}

.nav-links.scrolled {
  color: #555;
}

.nav-submenu {
  visibility: hidden;
  opacity: 0;
  display: none;
  position: absolute;
  border-radius: 0 0 7px 7px;
  transition: all 2s ease-in;
}

.nav-submenu.scrolled {
  background-color: white;
}

@media screen and (min-width: 960px) {
  .nav-menu li:hover ul.nav-submenu {
    visibility: visible;
    opacity: 1;
    display: block;
  }
}

.nav-item:hover > .nav-links{
  color: var(--rose);
}

.submenu-item:hover > .nav-links{
  color: var(--rose);
}

.fa-bars {
  color: white;
}

.fa-bars.scrolled {
  color: #555;
}

.fa-chevron-down {
  font-size: 10px;
  margin-left: 5px;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

ol, ul, li {
  list-style: none;
}

@media screen and (max-width: 960px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: white;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-submenu {
    position: relative;
  }

  .nav-item {
    height: auto;
    position: relative;
  }

  .nav-item.active ul.nav-submenu {
    visibility: visible;
    opacity: 1;
    display: block;
  }

  .nav-links {
    color: #555;
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    height: auto;
  }

  .nav-links:hover {
    background-color: #f3f3f3;
    color: var(--rose);
    border-radius: 0;
  }

  .navbar-logo {
    position: inherit;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .menu-icon {
    color: #555;
  }

  .fa-times {
    color: #555;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #555;
    padding: 14px 20px;
    border: 1px solid #555;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #f3f3f3;
    color: var(--rose);
    transition: 250ms;
  }
}

.logo-svg {
  height: 100%;
}
