.gallery {
    display                   : flex;
    width                     : 60%;
    flex-direction            : column;
    align-items               : center;
    margin                    : 10px auto;
    gap                       : 10px;
}

.gallery-items {
    display                   : flex;
    flex-direction            : row;
    gap                       : 10px;
}

.gallery-item {
    display                   : flex;
    list-style-type           : none;
}

@media only screen and (max-width: 960px) {
    .gallery {
        width                 : 90%;
    }

    .gallery-items {
        flex-direction        : column;
    }
}
