/* Common class for all photo blocs */
.photos {
    display: flex;
    width: 60%;
    justify-content: center;
    margin: auto;
    gap: 10px;
}

.photo-img {
    width: 100%;
}

@media only screen and (max-width: 960px) {
    .photos {
        width: 90%;
    }
}

.photo-single-img {
    width: 100%;
}

.photo-duo {
    flex-direction: row;
}

.photo-duo-img {
    width: 50%;
}

@media only screen and (max-width: 960px) {
    .photo-duo {
        flex-direction: column;
    }

    .photo-duo-img {
        width: 100%;
    }
}

.photo-gallery-img {
    width: 100%;
    object-fit: cover;
}

.photo-gallery-t {
    flex-direction: column;
}

/* Photo T */
.photo-gallery-item-top {
    width: 100%;
    object-fit: contain;
}

.photo-gallery-items-row {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.photo-gallery-item-row {
    object-fit: cover;
    width: 50%;
}

/* T Side */
.photo-gallery-item-side {
    width: 50%;
    object-fit: cover;
}

.photo-gallery-items-col {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 10px;
}

.photo-gallery-item-col {
    object-fit: cover;
    height: 50%;
    width: 100%;
}

@media only screen and (max-width: 960px) {
    .photo-gallery-t {
        width: 90%;
    }

    .photo-gallery-items-row {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .photo-gallery-item-row {
        object-fit: cover;
        width: 100%;
    }

    /* T Side */
    .photo-gallery-t-side {
        flex-direction: column;
    }

    .photo-gallery-item-side {
        width: 100%;
    }

    .photo-gallery-items-col {
        width: 100%;
    }
}
