
.subscriptions-container {
  padding: 20px 0;
  background-color: #f1f5f6;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.subscriptions-header {
  display: flex;
  flex-direction: row;
  gap: 5px;
  font-family: 'Staatliches', sans-serif;
  font-size: 30px;
  color:  #96b4bf;
}

.subscriptions-header-text {
  font-family: inherit;
}

.subscriptions-header-emphasis {
  font-family: inherit;
  color: #628999;
}

@media only screen and (max-width: 960px) {
  .subscriptions-header {
    font-size: 20px;
    flex-direction: column;
    align-items: center;
  }
}

/* subscription form ***************************************************************/
.subscription-form {
  background-color: #dde6e9ff;
  width: 40%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
}

@media only screen and (max-width: 960px) {
  .subscription-form {
    width: 90%;
  }
}

.subscription-form-elem {
  display: flex;
  gap: 10px;
}

.subscription-form-info {
  background-color: white;
  border: none;
  height: 40px;
  border-radius: 5px;
  flex-grow: 1;
  padding: 0 10px;
  color: #4c6c78;
}

.subscription-form-info:focus {
  outline: none;
}

.subscription-form-info.wrong {
  border: dashed #ff9292;
}

.subscription-form-btn-submit {
  transition: background-color 0.3s ease-out;
  height: 40px;
  background-color: #4c6c78;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 0 10px;
}

.subscription-form-btn-submit:hover {
  background-color: #38505a;
}

.subscription-form-btn-submit:active {
  background-color: #293b43;
}
