.title {
    font-family: 'Staatliches', sans-serif;
    font-size: 50px;
    text-align: center;
    color: #3b3b3b;
    margin: 40px auto;
}

.subtitle {
    font-family: 'Dosis', sans-serif;
    font-size: 26px;
    text-transform: uppercase;
    text-align: center;
    color: #3b3b3b;
    margin: 30px auto;
}

.text {
    font-family: 'Poppins', sans-serif;
    text-align: justify;
    font-size: 16px;
    font-style: normal;
    line-height: 23.8px;
    font-weight: 400;
    color: #555555;
}

@media screen and (max-width: 960px) {
    .title {
        font-size: 25px;
    }
    .subtitle {
        font-size: 20px;
    }
    .text {
        font-size: 14px;
    }
}
