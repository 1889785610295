.trip-card-gallery {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px auto;
    gap: 10px;
}

.trip-card-gallery-row {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
}

.trip-card-gallery-item {
    display: grid;
    justify-items: center;
    cursor: pointer;
    width: 100%;
}

.trip-card-gallery-item:hover > .trip-card-overlay {
    background: none;
}

.trip-card-elem {
    grid-row: 1;
    grid-column: 1;
}

.trip-card-overlay {
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 7px;
    background: #3b3b3b50;
    transition: background 0.3s ease-out;
}

.trip-card-title {
    width: 100%;
    z-index: 2;
    margin: auto;
    text-align: center;
    font-family: 'Staatliches';
    font-size: 4vw;
    font-weight: 600;
    color: white;
}

.trip-card-img {
    width: 100%;
    height: 100%;
    border-radius: 7px;
}

@media only screen and (max-width: 960px) {
    .trip-card-gallery {
        width: 80%;
    }

    .trip-card-title {
        font-size: 6vw;
    }
}
