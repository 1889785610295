.story_bloc {
  width: 60%;
  margin: 10px auto;
}

@media screen and (max-width: 960px) {
  .story_bloc {
    width: 90%;
  }
}
