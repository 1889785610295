* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.amsterdam {
  background-image: linear-gradient(#00000000, #00000030, #00000000), url('https://nid-de-poule.s3.eu-west-3.amazonaws.com/photos/cards/img-amsterdam.jpg');
}

.berlin {
  background-image: linear-gradient(#00000000, #00000030, #00000000), url('https://nid-de-poule.s3.eu-west-3.amazonaws.com/photos/cards/img-berlin.jpg');
}

.bretagne {
  background-image: linear-gradient(#00000000, #00000030, #00000000), url('https://nid-de-poule.s3.eu-west-3.amazonaws.com/photos/cards/img-bretagne.jpg');
}

.bourges {
  background-image: linear-gradient(#00000000, #00000030, #00000000), url('https://nid-de-poule.s3.eu-west-3.amazonaws.com/photos/cards/img-bourges.jpg');
}

.bourgogne {
  background-image: linear-gradient(#00000000, #00000030, #00000000), url('https://nid-de-poule.s3.eu-west-3.amazonaws.com/photos/cards/img-bourgogne.jpg');
}

.crete {
  background-image: linear-gradient(#00000000, #00000030, #00000000), url('https://nid-de-poule.s3.eu-west-3.amazonaws.com/photos/cards/img-crete.jpg');
}

.corse {
  background-image: linear-gradient(#00000000, #00000030, #00000000), url('https://nid-de-poule.s3.eu-west-3.amazonaws.com/photos/cards/img-corse.jpg');
}

.etretat {
  background-image: linear-gradient(#00000000, #00000030, #00000000), url('https://nid-de-poule.s3.eu-west-3.amazonaws.com/photos/cards/img-etretat.jpg');
}

.gacilly {
  background-image: linear-gradient(#00000000, #00000030, #00000000), url('https://nid-de-poule.s3.eu-west-3.amazonaws.com/photos/cards/img-gacilly.jpg');
}

.honfleur {
  background-image: linear-gradient(#00000000, #00000030, #00000000), url('https://nid-de-poule.s3.eu-west-3.amazonaws.com/photos/cards/img-honfleur.jpg');
}

.malta {
  background-image: linear-gradient(#00000000, #00000030, #00000000), url('https://nid-de-poule.s3.eu-west-3.amazonaws.com/photos/cards/img-malta.jpg');
}

.perche {
  background-image: linear-gradient(#00000000, #00000030, #00000000), url('https://nid-de-poule.s3.eu-west-3.amazonaws.com/photos/cards/img-perche.jpg');
}

.trouville {
  background-image: linear-gradient(#00000000, #00000030, #00000000), url('https://nid-de-poule.s3.eu-west-3.amazonaws.com/photos/cards/img-trouville.jpg');
}
