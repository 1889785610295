:root {
    --primary: #fff;
}

.btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
}

.btn--primary {
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);
}

.btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn-navbar {
    background-color: transparent;
    width: 110px;
    color: white;
    border: 2px solid white;
    transition: all 0.3s ease-out;
}

.btn-navbar.scrolled {
    border-color: #555;
    color: #555;
}

.btn-navbar:hover {
    background-color: transparent;
    color: #f78a74 !important;
    border: 2px solid white;
    transition: all 0.3s ease-out;
}

.btn-navbar.scrolled:hover {
    border-color: #555;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 14px;
}

.btn--large {
    font-size: 20px;
}

.btn--medium:hover,
.btn--large:hover {
    background: #fff;
    color: #242424;
    transition: all 0.3s ease-out;
}

@media screen and (max-width: 960px) {
    .btn-navbar {
        border-color: #555;
        color: #555;
    }
}
