.location-card-gallery {
    display                   : grid;
    gap                       : 10px;
    margin                    : 30px auto;
    width                     : 60%;
}

.location-card-items {
    display                   : flex;
    flex-direction            : row;
    gap                       : 10px;
    width                     : 100%;
    margin                    : auto;
    align-items               : center;
}

.location-card-elem {
   grid-row                   : 1;
   grid-column                : 1;
}

.location-card {
    display                   : grid;
    width                     : 100%;
    align-items               : flex-end;
    cursor                    : pointer;
    border-radius             : 7px;
    overflow                  : hidden;
}

.location-card > .location-card-img {
    width                     : 100%;
    aspect-ratio              : 3/2;
    object-fit                : cover;
    transition                : transform 0.3s ease-out;
}

.location-card:hover > .location-card-img {
    transform                 : scale(1.05);
}

.location-card-overlay {
    z-index                   : 1;
    width                     : 100%;
    height                    : 100%;
    opacity: 0;
}

.location-card:hover > .location-card-overlay {
    background                : linear-gradient(0deg, #00000066, #00000000);
    opacity                   : 1;
    transition                : all 0.3s ease-out;
}

.location-card > .location-card-desc {
    font-family               : 'Staatliches';
    font-size                 : 20px;
    display                   : flex;
    flex-direction            : column;
    padding                   : 10px;
    color                     : #ffffff;
    text-align                : center;
    z-index                   : 2;
    opacity                   : 1;
}

@media only screen and (max-width: 960px) {
    .location-card-gallery {
        width                 : 90%;
    }

    .location-card-items {
        flex-direction        : column;
    }
}
